import { Link } from "gatsby";
import styled from "styled-components";
import { black, white } from "theme/colors";

export const BackButton = styled(Link)`
  display: inline-block;
  position: relative;
  z-index: 5;
  width: auto;
  margin-left: 50%;
  line-height: 1;
  margin-bottom: 20px;
  transform: translateX(-50%);
  padding: 8px 15px;
  background-color: ${black.default};
  color: ${white.default};
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    bottom: 0;
    right: 0;
    transition: 0.4s ease;
  }
  &:hover {
    &::before {
      width: 100%;
      left: 0;
    }
  }
`;
