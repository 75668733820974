import styled from "styled-components";
import { toRem } from "utils/mixins";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;
  margin-bottom: ${toRem(30)};
  .preview_video {
    width: 100%;
    height: 100%;
    position: relative;
    svg {
      opacity: 0;
      transition: 250ms;
      position: absolute;
      left: 50%;
      top: 50%;
      height: ${toRem(40)};
      z-index: 3;
      transform: translate(-50%, -50%);
    }
    &:hover svg {
      opacity: 1;
    }
    video {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
`;
