import styled from "styled-components";
import { black, white } from "theme/colors";

export const PostBannerWrapper = styled.section`
  position: relative;
  height: auto;
  margin-top: 110px;
  overflow: hidden;
  .info {
    transform: translateY(50px);
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 40px 25px;

    transition: 400ms;
    width: 100%;
  }
  .gatsby-image-wrapper {
    height: 400px !important;
    width: 100% !important;
    img {
      transform: scale(1);
    }
  }
  @media (min-width: 769px) {
    .info {
      padding: 40px;
      width: 100%;
    }
  }
`;
