import React from "react";

// import VideoPlayer from "components/video";
import { Wrapper } from "./Video.styles";

const Video = (props) => {
  //PROPS
  // const { previewVideo, vimeo, videoID, setVideoID } = props;
  //PROPS

  return (
    <Wrapper className="container container--small">
      {/* <VideoPlayer
        id={vimeo ? vimeo : null}
        videoID={videoID}
        setVideoID={setVideoID}
        preview={previewVideo?.mediaItemUrl}
      /> */}
    </Wrapper>
  );
};

export default Video;
